import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Loader from './components/Loader/Loader';
import AdminLayout from './layouts/AdminLayout';
import InvestorLayout from './layouts/InvestorLayout';

import GuestGuard from './components/Auth/GuestGuard';
import AuthGuard from './components/Auth/AuthGuard';
import InvestorGuard from './components/Auth/InvestorGuard';

import { BASE_URL } from './config/constant';
import PublicGuard from './components/Auth/PublicGuard';

export const renderRoutes = (routes = []) => (
   <Suspense fallback={<Loader />}>
      <Switch>
         {routes.map((route, i) => {
            const Guard = route.guard || Fragment;
            const Layout = route.layout || Fragment;
            const Component = route.component;

            return (
               <Route
                  key={i}
                  path={route.path}
                  exact={route.exact}
                  render={props => (
                     <Guard key={props}>
                        <Layout>
                           {route.routes ? (
                              renderRoutes(route.routes)
                           ) : (
                              <Component {...props} />
                           )}
                        </Layout>
                     </Guard>
                  )}
               />
            );
         })}
      </Switch>
   </Suspense>
);

const routes = [
   {
      exact: true,
      guard: GuestGuard,
      path: '/auth/signin',
      component: lazy(() => import('./views/auth/signin/SignIn')),
   },
   {
      guard: PublicGuard,
      path: '/website/',
      component: lazy(() => import('./pages/website/')),
   },
   {
      guard: PublicGuard,
      path: '/easyhomeslogo',
      component: lazy(() => import('./assets/images/easyhomes.png')),
   },
   {
      exact: true,
      path: '/famportal/',
      guard: AuthGuard,
      component: lazy(() => import('./pages/portal/PortalMaster')),
   },
   {
      exact: true,
      path: '/famportal/requests',
      guard: AuthGuard,
      component: lazy(() => import('./pages/portal/FamilyRequestList')),
   },
   {
      exact: true,
      path: '/famportal/requests/:id',
      guard: AuthGuard,
      component: lazy(() => import('./pages/portal/FamilyRequestForm')),
   },
   {
      exact: true,
      path: '/famportal/requests/create',
      guard: AuthGuard,
      component: lazy(() => import('./pages/portal/FamilyRequestForm')),
   },
   {
      exact: true,
      guard: GuestGuard,
      path: '/auth/signup',
      component: lazy(() => import('./views/auth/signup/SignUp')),
   },
   {
      exact: true,
      path: '/404',
      component: lazy(() => import('./views/errors/NotFound404')),
   },
   {
      exact: true,
      path: '/maintenance/coming-soon',
      component: lazy(() => import('./views/maintenance/ComingSoon')),
   },
   {
      exact: true,
      path: '/maintenance/error',
      component: lazy(() => import('./views/maintenance/Error')),
   },
   {
      exact: true,
      path: '/maintenance/offline-ui',
      component: lazy(() => import('./views/maintenance/OfflineUI')),
   },
   {
      exact: true,
      path: '/auth/signup-1',
      component: lazy(() => import('./views/auth/signup/SignUp1')),
   },
   {
      exact: true,
      path: '/auth/signup-2',
      component: lazy(() => import('./views/auth/signup/SignUp2')),
   },
   {
      exact: true,
      path: '/auth/signup-3',
      component: lazy(() => import('./views/auth/signup/SignUp3')),
   },
   {
      exact: true,
      path: '/auth/signup-4',
      component: lazy(() => import('./views/auth/signup/SignUp4')),
   },
   {
      exact: true,
      path: '/auth/signup-5',
      component: lazy(() => import('./views/auth/signup/SignUp5')),
   },
   {
      exact: true,
      path: '/rtp/pdf',
      guard: AuthGuard,
      component: lazy(() => import('./pages/trust/PDFGen')),
   },
   {
      exact: true,
      path: '/auth/signin-1',
      component: lazy(() => import('./views/auth/signin/SignIn1')),
   },
   {
      exact: true,
      path: '/auth/signin-2',
      component: lazy(() => import('./views/auth/signin/SignIn2')),
   },
   {
      exact: true,
      path: '/auth/signin-3',
      component: lazy(() => import('./views/auth/signin/SignIn3')),
   },
   {
      exact: true,
      path: '/auth/signin-4',
      component: lazy(() => import('./views/auth/signin/SignIn4')),
   },
   {
      exact: true,
      path: '/auth/signin-5',
      component: lazy(() => import('./views/auth/signin/SignIn5')),
   },
   {
      exact: true,
      path: '/auth/reset-password-1',
      component: lazy(() =>
         import('./views/auth/reset-password/ResetPassword1')
      ),
   },
   {
      exact: true,
      path: '/auth/account-verify',
      component: lazy(() =>
         import('./views/auth/account_verification/VerifyAccount')
      ),
   },
   {
      exact: true,
      path: '/auth/reset-password-2',
      component: lazy(() =>
         import('./views/auth/reset-password/ResetPassword2')
      ),
   },
   {
      exact: true,
      path: '/auth/reset-password-3',
      component: lazy(() =>
         import('./views/auth/reset-password/ResetPassword3')
      ),
   },
   {
      exact: true,
      path: '/auth/reset-password-4',
      component: lazy(() =>
         import('./views/auth/reset-password/ResetPassword4')
      ),
   },
   {
      exact: true,
      path: '/auth/reset-password-5',
      component: lazy(() =>
         import('./views/auth/reset-password/ResetPassword5')
      ),
   },
   {
      exact: true,
      path: '/account/reset-password/:id',
      component: lazy(() => import('./pages/auth/ChangePassword')),
   },
   {
      exact: true,
      path: '/account/verify/:id',
      component: lazy(() => import('./pages/auth/Verify')),
   },
   {
      exact: true,
      path: '/auth/change-password',
      component: lazy(() => import('./views/auth/ChangePassword')),
   },
   {
      exact: true,
      path: '/auth/profile-settings',
      component: lazy(() => import('./views/auth/ProfileSettings')),
   },

   {
      exact: true,
      path: '/auth/tabs-auth',
      component: lazy(() => import('./views/auth/TabsAuth')),
   },
   {
      exact: true,
      path: '/auth/map-form',
      component: lazy(() => import('./views/auth/MapForm')),
   },
   {
      exact: true,
      path: '/auth/subscribe',
      component: lazy(() => import('./views/auth/Subscribe')),
   },
   {
      path: '/investor-portal',
      layout: InvestorLayout,
      guard: AuthGuard,
      routes: [
         {
            exact: true,
            path: '/investor-portal/monthly',
            component: lazy(() => import('./pages/portal/investor/InvestorMonthly')),
         },
         {
            exact: true,
            path: '/investor-portal/yearly',
            component: lazy(() => import('./pages/portal/investor/InvestorYearly')),
         },
         // {
         //    exact: true,
         //    path: '/investor-portal/report',
         //    component: lazy(() => import('./pages/portal/investor/InvestorReport')),
         // },
         {
            exact: true,
            path: '/investor-portal/marketing',
            component: lazy(() => import('./pages/portal/investor/InvestorMarketing')),
         },
         // {
         //    exact: true,
         //    path: '',
         //    component: lazy(() => import('./pages/portal/investor/InvestorPortal')),
         // },
         {
            exact: true,
            path: '/investor-portal/requests',
            guard: AuthGuard,
            component: lazy(() => import('./pages/portal/UserRequestList')),
         },
         {
            exact: true,
            path: '/investor-portal/requests/:id',
            guard: AuthGuard,
            component: lazy(() => import('./pages/portal/UserRequestForm')),
         },
         {
            exact: true,
            path: '/investor-portal/requests/create',
            guard: AuthGuard,
            component: lazy(() => import('./pages/portal/UserRequestForm')),
         },
      ],
   },
   // {
   //    exact: true,
   //    path: '/landing',
   //    component: lazy(() => import('./views/landing')),
   // },
   {
      path: '*',
      layout: AdminLayout,
      guard: AuthGuard,
      routes: [
         {
            exact: true,
            path: '/loans/list',
            component: lazy(() => import('./pages/loans/LoanList')),
         },
         {
            exact: true,
            path: '/loans/createLoan',
            component: lazy(() => import('./pages/loans/CreateLoan')),
         },
         {
            exact: true,
            path: '/loans/:id',
            component: lazy(() => import('./pages/loans/LoanDetail')),
         },

         {
            exact: true,
            path: '/acquisitions/list',
            component: lazy(() => import('./pages/acquisitions/AcqList')),
         },
         {
            exact: true,
            path: '/acquisitions/disapproved',
            component: lazy(() => import('./pages/acquisitions/DisapprovedList')),
         },
         // {
         //    exact: true,
         //    path: '/acquisitions/scrape',
         //    component: lazy(() =>
         //       import('./pages/acquisitions/AcqStartScrape')
         //    ),
         // },
         // {
         //    exact: true,
         //    path: '/acquisitions/scrape/list',
         //    component: lazy(() => import('./pages/acquisitions/ScrapeList')),
         // },
         // {
         //    exact: true,
         //    path: '/acquisitions/scrape/:id',
         //    component: lazy(() => import('./pages/acquisitions/ScrapeDetail')),
         // },
         {
            exact: true,
            path: '/acquisitions/:id',
            component: lazy(() =>
               import('./pages/acquisitions/PropertyMainDetails')
            ),
         },
         {
            exact: true,
            path: '/ambassador/list',
            component: lazy(() => import('./pages/ambassador/AmbassadorList')),
         },
         // {
         //    exact: true,
         //    path: '/ambassador/kanban',
         //    component: lazy(() =>
         //       import('./pages/ambassador/AmbassadorKanban')
         //    ),
         // },
         {
            exact: true,
            path: '/ambassador/:id',
            component: lazy(() =>
               import('./pages/ambassador/AmbassadorDetail')
            ),
         },

         {
            exact: true,
            path: '/trust/list',
            component: lazy(() => import('./pages/trust/TrustList')),
         },
         {
            exact: true,
            path: '/accounting/',
         },
         {
            exact: true,
            path: '/accounting/banks/list',
            component: lazy(() => import('./pages/accounting/BankAccountsList')),
         },

         {
            exact: true,
            path: '/accounting/reconcile',
            component: lazy(() => import('./pages/accounting/BankLedger')),
         },
         {
            exact: true,
            path: '/accounting/verifyPayments',
            component: lazy(() => import('./pages/accounting/VerifyLoanPayments')),
         },
          {
            exact: true,
            path: '/accounting/banks/:id',
            component: lazy(() => import('./pages/accounting/tables/BankLedgerGrid')),
         },
          {
            exact: true,
            path: '/accounting/masterLedger',
            component: lazy(() => import('./pages/accounting/tables/MasterLedgerGrid')),
         },
         {
            exact: true,
            path: '/accounting/escrow',
            component: lazy(() => import('./pages/accounting/EscrowLedger')),
         },
         {
            exact: true,
            path: '/accounting/wallet',
            component: lazy(() => import('./pages/accounting/PiWallet')),
         },
         {
            exact: true,
            path: '/accounting/collections',
            component: lazy(() => import('./pages/accounting/Collections')),
         },

         // {
         //    exact: true,
         //    path: '/trust/kanban',
         //    component: lazy(() => import('./pages/trust/TrustKanban')),
         // },
         {
            exact: true,
            path: '/trust',
            component: lazy(() => import('./pages/trust/CreateTrust')),
         },
         {
            exact: true,
            path: '/rtp',
            component: lazy(() => import('./pages/trust/TrustRTP')),
         },
         // {
         //    exact: true,
         //    path: '/situations',
         //    component: lazy(() => import('./pages/trust/TrustSituations')),
         // },
         // {
         //    exact: true,
         //    path: '/mytasks',
         //    component: lazy(() => import('./pages/trust/MyTasks')),
         // },
         // {
         //    exact: true,
         //    path: '/situations/:id',
         //    component: lazy(() =>
         //       import('./pages/trust/TrustSituationDetails')
         //    ),
         // },
         {
            exact: true,
            path: '/trust/:id',
            component: lazy(() => import('./pages/trust/TrustDetail')),
         },
         // {
         //    exact: true,
         //    path: '/address',
         //    component: lazy(() => import('./pages/address/Address')),
         // },
         {
            exact: true,
            path: '/contact/list',
            component: lazy(() => import('./pages/contact/ContactList')),
         },
         // {
         //     exact: true,
         //     path: '/contact/kanban',
         //     component: lazy(() => import('./pages/contact/vendor/ContactKanban'))
         // },
         {
            exact: true,
            path: '/contact/:id',
            component: lazy(() =>
               import('./pages/contact/FullContactDetail')
            ),
         },
         {
            exact: true,
            path: '/investor/list',
            component: lazy(() => import('./pages/investor/InvestorList')),
         },
         // {
         //    exact: true,
         //    path: '/investor/list',
         //    component: lazy(() => import('./pages/investor/InvestorList')),
         // },
         {
            exact: true,
            path: '/investor/:id',
            component: lazy(() => import('./pages/investor/InvestorDetail')),
         },
         {
            exact: true,
            path: '/user-requests/investor',
            component: lazy(() => import('./pages/requests/UserRequestsList'))
         },
         {
            exact: true,
            path: '/user-requests/investor/create',
            component: lazy(() => import('./pages/requests/UserRequestForm'))
         },
         {
            exact: true,
            path: '/familylead/list',
            component: lazy(() =>
               import('./pages/leads/family/FamilyLeadList')
            ),
         },
         {
            exact: true,
            path: '/familylead/kanban',
            component: lazy(() =>
               import('./pages/leads/family/FamilyLeadKanban')
            ),
         },
         {
            exact: true,
            path: '/familylead/:id',
            component: lazy(() =>
               import('./pages/leads/family/FamilyLeadDetail')
            ),
         },
          {
            exact: true,
            path: '/applications/:id',
            component: lazy(() =>
               import('./pages/contact/tabs/ContactApplications')
            ),
         },
          {
            exact: true,
            path: '/applications',
            component: lazy(() =>
               import('./pages/leads/family/ApplicationList')
            ),
         },
         {
            exact: true,
            path: '/user-requests/family',
            component: lazy(() => import('./pages/requests/UserRequestsList'))
         },
         {
            exact: true,
            path: '/user-requests/family/create',
            component: lazy(() => import('./pages/requests/UserRequestForm'))
         },
         {
            exact: true,
            path: '/investorlead/list',
            component: lazy(() =>
               import('./pages/leads/investor/InvestorLeadList')
            ),
         },
         {
            exact: true,
            path: '/user-requests',
            component: lazy(() =>
               import('./pages/requests/UserRequestsList')
            ),
         },
         {
            exact: true,
            path: '/user-requests/:id',
            component: lazy(() =>
               import('./pages/requests/UserRequestForm')
            ),
         },
         {
            exact: true,
            path: '/user-requests/create',
            component: lazy(() =>
               import('./pages/requests/UserRequestForm')
            ),
         },
         // {
         //    exact: true,
         //    path: '/investorlead/kanban',
         //    component: lazy(() =>
         //       import('./pages/leads/investor/InvestorLeadKanban')
         //    ),
         // },
         {
            exact: true,
            path: '/investorlead/:id',
            component: lazy(() =>
               import('./pages/leads/investor/InvestorLeadDetail')
            ),
         },
         {
            exact: true,
            path: '/family/list',
            component: lazy(() => import('./pages/family/FamilyList')),
         },

         {
            exact: true,
            path: '/family/:id',
            component: lazy(() => import('./pages/family/FamilyDetail')),
         },
         // maps
         {
            exact: true,
            path: '/maps',
            component: lazy(() => import('./components/Map/Map')),
         },
         // tickets
         {
            exact: true,
            path: '/tickets/hr',
            component: lazy(() => import('./pages/tickets/hr/HrList')),
         },
         {
            exact: true,
            path: '/tickets/hr/create',
            component: lazy(() => import('./pages/tickets/hr/HrCreate')),
         },
         {
            exact: true,
            path: '/tickets/hr/:id',
            component: lazy(() => import('./pages/tickets/hr/HrDetail')),
         },
         {
            exact: true,
            path: '/tickets/it',
            component: lazy(() => import('./pages/tickets/it/ItList')),
         },
         {
            exact: true,
            path: '/tickets/it/create',
            component: lazy(() => import('./pages/tickets/it/ItCreate')),
         },
         {
            exact: true,
            path: '/tickets/it/:id',
            component: lazy(() => import('./pages/tickets/it/ItDetail')),
         },
         {
            exact: true,
            path: '/tickets/sr',
            component: lazy(() => import('./components/Map/Map')),
         },
         {
            exact: true,
            path: '/tickets/sr/create',
            component: lazy(() => import('./components/Map/Map')),
         },
         {
            exact: true,
            path: '/tickets/sr/:id',
            component: lazy(() => import('./components/Map/Map')),
         },
         {
            exact: true,
            path: '/tickets/qual',
            component: lazy(() => import('./pages/tickets/qual/QualList')),
         },
         {
            exact: true,
            path: '/tickets/qual/create',
            component: lazy(() => import('./pages/tickets/qual/QualCreate')),
         },
         {
            exact: true,
            path: '/tickets/qual/:id',
            component: lazy(() => import('./pages/tickets/qual/QualDetail')),
         },
         // admin
         {
            exact: true,
            path: '/admin/dashboard',
            component: lazy(() => import('./pages/portal/EmployeeDashboard')),
         },
         {
            exact: true,
            path: '/projects/create',
            component: lazy(() => import('./pages/projects/ProjectCreate')),
         },
         {
            exact: true,
            path: '/projects/:id',
            component: lazy(() => import('./pages/projects/ProjectDetail')),
         },
         {
            exact: true,
            path: '/projects/',
            component: lazy(() => import('./pages/projects/Projects')),
         },
         {
            exact: true,
            path: '/projects/task/:id',
            component: lazy(() => import('./pages/projects/Items/ProjectItem')),
         },
         {
            path: '*',
            exact: true,
            component: () => <Redirect to={BASE_URL} />,
         },
      ],
   }
];

export default routes;
